<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="100px">
			<el-form-item class="el_form_item" label="运单id">
				<el-input class="el_input" v-model="form.id" placeholder="id搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上游编号">
				<el-input class="el_input" v-model="form.out_trade_num" placeholder="上游编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="线路编号">
				<el-input class="el_input" v-model="form.bl_num" placeholder="线路编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主手机">
				<el-input class="el_input" v-model="form.shipper_tel" placeholder="货主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="非货主手机">
				<el-input class="el_input" v-model="form.shipper_tel_not" placeholder="非货主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主公司">
				<el-input class="el_input" v-model="form.shipper_company_name" placeholder="货主公司名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="收款人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人名称">
				<el-input class="el_input" v-model="form.truck_owner_name" placeholder="收款人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机姓名">
				<el-input class="el_input" v-model="form.driver_name" placeholder="司机姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="车牌号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="大区搜索">
				<el-input class="el_input" v-model="form.area_id" placeholder="大区名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="销售电话">
				<el-input class="el_input" v-model="form.saler_tel" placeholder="销售电话搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未发车" value="1"></el-option>
					<el-option label="已发车" value="2"></el-option>
					<el-option label="已到货" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付状态">
				<el-select class="el_input" v-model="form.payed_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未支付" value="1"></el-option>
					<el-option label="部分支付" value="2"></el-option>
					<el-option label="支付完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="开票状态">
				<el-select class="el_input" v-model="form.i_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未开票" value="1"></el-option>
					<el-option label="开票中" value="2"></el-option>
					<el-option label="已开票" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算状态">
				<el-select class="el_input" v-model="form.settled_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未结算" value="1"></el-option>
					<el-option label="部分结算" value="2"></el-option>
					<el-option label="结算完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="上报状态">
				<el-select class="el_input" v-model="form.upl_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未上报" value="1"></el-option>
					<el-option label="运单上报成功" value="2"></el-option>
					<el-option label="运单上报失败" value="3"></el-option>
					<el-option label="流水上报成功" value="4"></el-option>
					<el-option label="流水上报失败" value="5"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单删除">
				<el-select class="el_input" v-model="form.is_show" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="待审核" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="运费设置">
				<el-select class="el_input" v-model="form.is_set_freight" clearable>
					<el-option label="不限" value="1"></el-option>
					<el-option label="已设置" value="2"></el-option>
					<el-option label="未设置" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="轨迹校验">
				<el-select class="el_input" v-model="form.check_act_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="验证通过" value="1"></el-option>
					<el-option label="未验证" value="2"></el-option>
					<el-option label="验证失败" value="3"></el-option>
					<el-option label="无轨迹" value="4"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="回单上传">
				<el-select class="el_input" v-model="form.voucher_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="已上传" value="1"></el-option>
					<el-option label="未上传" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="etc开票">
				<el-select class="el_input" v-model="form.etc_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未开票" value="1"></el-option>
					<el-option label="已开票" value="2"></el-option>
					<el-option label="开票失败" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="线路搜索" >
				<el-autocomplete
				class="inline-input"
				v-model="form.bl_name"
				:fetch-suggestions="querySearch"
				placeholder="线路名称"
				@focus="bl_focus"
				></el-autocomplete>
			</el-form-item>
			<el-form-item class="el_form_item" label="项目名称">
				<el-input class="el_input" v-model="form.item_name" placeholder="项目名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="省监管上报" v-if="user_info.tel!='19155606858'">
				<el-select class="el_input" v-model="form.logink_up_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未上传" value="1"></el-option>
					<el-option label="一次上传失败" value="0"></el-option>
					<el-option label="一次已传" value="2"></el-option>
					<el-option label="二次已传" value="3"></el-option>
					<el-option label="车辆已传" value="4"></el-option>
					<el-option label="司机已传" value="5"></el-option>
					<el-option label="承运人已传" value="6"></el-option>
					<el-option label="三次已传" value="7"></el-option>
					<el-option label="流水已传" value="8"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="保障服务费">
				<el-select class="el_input" v-model="form.is_set_insure" clearable>
					<el-option label="不限" value="1"></el-option>
					<el-option label="已设置" value="2"></el-option>
					<el-option label="未设置" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="有无发邮件">
				<el-select class="el_input" v-model="form.insure_status" clearable>
					<el-option label="不限" value="3"></el-option>
					<el-option label="已发送" value="1"></el-option>
					<el-option label="未发送" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否垫资">
				<el-select class="el_input" v-model="form.is_yufu" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否回款">
				<el-select class="el_input" v-model="form.is_admin_pay_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="发到时间">
				<div class="block">
				<el-date-picker
					v-model="date_value"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="发车日期"
					end-placeholder="到货日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="开票时间">
				<div class="block">
				<el-date-picker
					v-model="invoice_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="建单时间">
				<div class="block">
				<el-date-picker
					v-model="creat_time_arr1"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="邮件发送时间">
				<div class="block">
				<el-date-picker
					v-model="insure_time_arr"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="监管上报时间">
				<div class="block">
				<el-date-picker
					v-model="logink_up_time_arr"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_form_open">更多条件</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" @click="tords_freight_check">批量运费审核</el-button>
				<el-button type="primary" @click="tord_export_open">导出搜索结果</el-button>
				<el-button type="primary" @click="tord_abnormal_select">异常轨迹运单筛选</el-button>
				<el-button type="primary" @click="tord_abnormal_init">异常运单初始化</el-button>
				<el-button type="primary"  @click="batch_download_voucher">批量下载凭证</el-button>
				<el-button type="primary"  @click="batch_mark">填写发票备注</el-button>
				<el-button type="primary"  @click="set_tord_mail">批量发送邮件</el-button>
				<el-button type="primary"  @click="batch_set_insure_status(0)">批量清除邮件</el-button>
				<el-button type="primary"  @click="batch_lock_open_msg(0)">批量锁定</el-button>
				<el-button type="primary"  @click="batch_unlock(0)">批量解锁</el-button>
				<el-button type="primary"  @click="batch_delete_open_msg(0)">批量删除</el-button>
				<el-button type="primary"  @click="batch_cancel_delete(0,1)">批量取消删除</el-button>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary"  @click="batch_cancel_delete(0,3)">批量确认删除</el-button>
			</el-form-item>
		</el-form>
		<div class="total_info item">
			<span>共:{{list.total}}条</span>
			<span>总计:{{total}}元</span>
			<span>总运费:{{list.total_freight}}元</span>
			<span v-if="list.emailed_bili">保险费覆盖率:{{list.emailed_bili}}</span>
			<span v-if="list.total_service>0">服务费{{list.total_service}}元</span>
		</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" @selection-change="tords_list_choose" >
				<el-table-column type="selection" width="39px"></el-table-column>
				<el-table-column label="运单id" width="100px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.id}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单/组别/上游单号" width="170px">
					<template slot-scope="scope">
						<div class="tab_line_item">线路{{scope.row.bl_num}}</div>
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">组别:{{scope.row.group1_num?scope.row.group1_num:"无"}}</div>
						<div class="tab_line_item">上游:{{scope.row.out_trade_num?scope.row.out_trade_num:"无"}}</div>
						<div v-if="scope.row.item_name">项目名称:{{scope.row.item_name}}</div>
						<div class="tab_line_item" v-if="scope.row.tax_num">发票号码：{{scope.row.tax_num}}</div>
						<div class="tab_line_item" v-if="scope.row.tax_code">发票代码：{{scope.row.tax_code}}</div>
						<div class="tab_line_item" v-if="scope.row.bl_info.consignee">收货方:{{scope.row.bl_info.consignee}}</div>
						<!-- <div class="tab_line_item" v-if="scope.row.consigneeid">收货方信用代码:{{scope.row.consigneeid}}</div> -->
						<div class="tab_line_item" v-if="scope.row.bl_info.seller_name">销售方:{{scope.row.bl_info.seller_name}}</div>
						<!-- <div class="tab_line_item" v-if="scope.row.seller_code">销售方信用代码:{{scope.row.seller_code}}</div> -->
					</template>
				</el-table-column>
				<el-table-column label="装卸货地信息"  width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">发货地:{{scope.row.case_prov}}/{{scope.row.case_city}}/{{scope.row.case_county}}/{{scope.row.case_other_obj.addr_info?scope.row.case_other_obj.addr_info:'无'}}</div>
						<div class="tab_line_item">卸货地:{{scope.row.aim_prov}}/{{scope.row.aim_city}}/{{scope.row.aim_county}}/{{scope.row.aim_other_obj.addr_info?scope.row.aim_other_obj.addr_info:'无'}}</div>
						<div class="tab_line_item">发车日期:{{scope.row.case_other_obj.start_time}}{{(scope.row.case_other_obj.start_time_is_check_text)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_type}}/{{scope.row.cargo_name}}</div>
						<div class="tab_line_item">发：{{parseFloat(scope.row.cargo_weight_start)==0?scope.row.cargo_weight +'吨':scope.row.cargo_weight_start+"吨"}}</div>
						<div class="tab_line_item">到：{{parseFloat(scope.row.cargo_weight_arrived)==0?scope.row.cargo_weight+'吨' :scope.row.cargo_weight_arrived+"吨"}}</div>
						<div class="tab_line_item">重量：{{scope.row.cargo_weight+'吨'}}</div>
						<div class="tab_line_item">体积：{{scope.row.cargo_volume==0?"无体积":scope.row.cargo_volume+"方"}}</div>
						<div class="tab_line_item">{{scope.row.settlement_type_text}}/{{scope.row.is_need_return_text}}</div>
						<div class="tab_line_item">距离:{{scope.row.distance}}KM</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/服务费/支付状态" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">总运费:{{scope.row.cost_info.freight_total_text}}</div>
						<div class="tab_line_item">司机:{{scope.row.cost_info.give_driver_total}}</div>
						<div class="tab_line_item" v-if="class_hide">收款人:{{scope.row.cost_info.give_truck_owner_total}}</div>
						<div class="tab_line_item">总服务费:{{scope.row.cost_info.service_charge}}</div>
						<div class="tab_line_item">保障服务费:{{scope.row.cost_info.insure_total}}</div>
						<div class="tab_line_item">垫资服务费:{{scope.row.cost_info.service_charge_yufu}}</div>
						<div class="tab_line_item">已支付服务费:{{scope.row.service_total}}</div>
						<div class="tab_line_item">货物价值:{{scope.row.cost_info.goods_total}}</div>
						<div class="tab_line_item">预计开票金额:{{scope.row.should_invoice_total}}</div>
						<div class="tab_line_item">已开票金额:{{scope.row.cost_info.invoiced_total}}</div>
						<div class="tab_line_item">
							{{scope.row.payed_status_text}} 
							<el-button class="btn_left" @click="tord_payed_list1(scope.row,'tord_pay_list')" size="mini" type="text">支付记录</el-button>
						</div>
						<div class="tab_line_item" v-if="user_info.tel!='19155606858'">
							{{scope.row.settled_status_text}} 
							<el-button class="btn_left" @click="tord_payed_list(scope.row,'tord_pay_list')" size="mini" type="text">结算记录</el-button>
						</div>
						<div class="tab_line_item">是否垫资:{{scope.row.is_yufu==1?'是':'否'}}</div>
						<div class="tab_line_item">是否回款:{{scope.row.is_admin_pay_return==1?'是':'否'}}</div>
						<div class="tab_line_item" v-if="scope.row.is_yufu==1">
							<el-button class="btn_left" @click="yufu_info_open(scope.row)" size="mini" type="text">垫资详情</el-button>
						</div>
						<!-- <div class="tab_line_item" v-if="scope.row.yufu_apply_time>0">预付申请时间:{{scope.row.yufu_apply_time_text}}</div> -->
					
					</template>
				</el-table-column>
				<el-table-column label="货主信息" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.shipper_info.name}}/{{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.shipper_company_info.name}}</div>		
					</template>
				</el-table-column>
				<el-table-column label="收款人/司机/货车信息" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item" v-if="class_hide">收款人:{{scope.row.truck_owner_info.name}}/{{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item">司机:{{scope.row.driver_info.name}}/{{scope.row.driver_info.tel}}</div>
						<el-button  class="btn_left" @click="driver_info_open(scope.row)" size="mini" type="text">司机详情</el-button>
						<div class="tab_line_item">{{scope.row.truck_plate_num}} <span  v-if="user_info.tel!='19155606858'">{{scope.row.truck_info.truck_type_name_text}}</span></div>
						<el-button  class="btn_left" @click="truck_info_open(scope.row)" size="mini" type="text">车辆详情</el-button>
					</template>
				</el-table-column>
				<!-- <el-table-column label="操作网点" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">姓名:{{scope.row.outlets_info.name?scope.row.outlets_info.name:'无'}}</div>
						<div class="tab_line_item">电话:{{scope.row.outlets_info.tel?scope.row.outlets_info.tel:'无'}}</div>
						<div class="tab_line_item">网点:{{scope.row.outlets_info.outlets_name?scope.row.outlets_info.outlets_name:'无'}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="运单状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.status_text}}</div>
						<div class="tab_line_item">
							{{scope.row.lock_status_text}} 
							<el-button @click="tord_unlock(scope.row)" size="mini" type="text" v-if="scope.row.lock_status==1">解锁</el-button>
							<el-button @click="tord_lock_open(scope.row)" size="mini" type="text" v-if="scope.row.lock_status==2" style="color:red">锁定</el-button>
						</div>
						<el-button @click="tord_lock_reason_show(scope.row)" size="mini" type="text" v-if="scope.row.lock_status==1" style="color:red">查看原因</el-button>
						<div class="tab_line_item" v-if="user_info.tel!='19155606858'">{{scope.row.upl_text}}</div>
						<div class="tab_line_item" style="color:red;font-size:16px;font-weight: bolder;" v-if="scope.row.is_show ==2">已删除</div>
						<el-button @click="tord_lock_delete_show(scope.row)" size="mini" type="text" v-if="scope.row.is_show==2" style="color:red">查看删除原因</el-button>
						<div class="tab_line_item"  v-if="user_info.tel!='19155606858'">{{scope.row.check_act_status_text}}</div>
						<div class="tab_line_item" style="color:red">{{scope.row.text}}</div>
						<div class="tab_line_item" v-if="scope.row.insure_status">邮件状态:{{scope.row.insure_status_text}}</div>
						<div class="tab_line_item" >轨迹状态:{{scope.row.trajectory_status_text}}</div>
						
					</template>
				</el-table-column>
				<el-table-column label="上报状态" width="80px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.tax_upl_status_text}}</div>
						<div class="tab_line_item"  v-if="user_info.tel!='19155606858'">省:{{scope.row.logink_up_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="时效信息" width="155px">
					<template slot-scope="scope">
						
						<div class="tab_line_item">建:{{scope.row.creat_time_text}}</div>
						<div class="tab_line_item">接:{{scope.row.deal_time_text}}</div>
						<div class="tab_line_item">发:{{scope.row.start_time_text}}</div>
						<div class="tab_line_item">到:{{scope.row.arrived_time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="132">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==1" class="btn_left" @click="tord_start(scope.row)" size="mini" type="text">发车</el-button>
						<el-button v-if="scope.row.status==2" class="btn_left" @click="tord_arrived(scope.row)" size="mini" type="text">到货</el-button>
						<el-button v-if="scope.row.status==3" class="btn_left" @click="tord_upl_open(scope.row)" size="mini" type="text">上报</el-button>
						<el-button @click="voucher_show(scope.row)" size="mini" type="text" >凭证</el-button>
						<el-button @click="tord_delete(scope.row)" size="mini" type="text" class="btn_delete" style="color:red">删除</el-button>
						<el-button @click="tord_edit(scope.row)" class="btn_left" size="mini" type="text">运单修改</el-button>
						<el-button @click="freight_edit_open(scope.row)" size="mini" type="text" >运费修改</el-button>
						<el-button @click="tord_set_open(scope.row)" class="btn_left" size="mini" type="text">参数</el-button>
						<el-button @click="truck_trajectory_open(scope.row)" size="mini" type="text">轨迹</el-button>
						<el-button v-if="scope.row.is_show == 2" @click="act_delete(scope.row,3)"  class="btn_left" size="mini" type="text" >审核删除</el-button>
						<el-button v-if="scope.row.is_show == 2" @click="act_delete(scope.row,1)"  size="mini" type="text" >取消删除</el-button>
						<el-button  class="btn_left" @click="show_agreement(scope.row)" size="mini" type="text" >查看协议</el-button>
						<el-button v-if="scope.row.insure_status==2" class="mini" @click="set_insure_status(scope.row)" size="mini" type="text" >清除邮件</el-button>
						<el-button
							v-if="scope.row.payed_status==1"
							class="btn_left" @click="tord_bl_edit_by_shipper(scope.row,1)" size="mini" type="text">
							更换线路
						</el-button>
						<el-button
							v-if="scope.row.payed_status==1 && scope.row.bl_num"
							class="mini" @click="tord_bl_edit_by_shipper(scope.row,2)" size="mini" type="text">
							同步线路
						</el-button>

					<el-button class="btn_left" @click="tord_pay_creat_by_admin(scope.row,1)" size="mini" type="text">
						垫资支付
					</el-button>
					<el-button class="mini" @click="binding_bankcard(scope.row,1)" size="mini" type="text">
						绑卡
					</el-button>
					<el-button class="btn_left" @click="invoicing_ord_cancel(scope.row,1)" size="mini" type="text">
						撤销开票申请
					</el-button>
					<el-button class="btn_left" @click="tord_edit_record(scope.row)" size="mini" type="text">修改记录</el-button>
					</template>
				</el-table-column>
					<!-- <el-table-column label="操作" width="132">
					<template slot-scope="scope">
						<el-button class="btn_left" @click="tord_start(scope.row)" size="mini" type="text">发车</el-button>
						<el-button  class="btn_left" @click="tord_arrived(scope.row)" size="mini" type="text">到货</el-button>
						<el-button class="btn_left" @click="tord_upl_open(scope.row)" size="mini" type="text">上报</el-button>
						<el-button @click="voucher_show(scope.row)" size="mini" type="text" >凭证</el-button>
						<el-button @click="tord_delete(scope.row)" size="mini" type="text" class="btn_delete" style="color:red">删除</el-button>
						<el-button @click="tord_edit(scope.row)" class="btn_left" size="mini" type="text">运单修改</el-button>
						<el-button @click="freight_edit_open(scope.row)" size="mini" type="text" >运费修改</el-button>
						<el-button @click="tord_set_open(scope.row)" class="btn_left" size="mini" type="text">参数</el-button>
						<el-button @click="truck_trajectory_open(scope.row)" size="mini" type="text">轨迹</el-button>
						<el-button  @click="act_delete(scope.row,3)"  class="btn_left" size="mini" type="text" >审核删除</el-button>
						<el-button  @click="act_delete(scope.row,1)"  size="mini" type="text" >取消删除</el-button>
						<el-button  class="btn_left" @click="show_agreement(scope.row)" size="mini" type="text" >查看协议</el-button>
						<el-button  class="mini" @click="set_insure_status(scope.row)" size="mini" type="text" >清除邮件</el-button>
						<el-button
							class="btn_left" @click="tord_bl_edit_by_shipper(scope.row,1)" size="mini" type="text">
							更换线路
						</el-button>
						<el-button
							class="mini" @click="tord_bl_edit_by_shipper(scope.row,2)" size="mini" type="text">
							同步线路
						</el-button>

					<el-button class="btn_left" @click="tord_pay_creat_by_admin(scope.row,1)" size="mini" type="text">
						垫资支付
					</el-button>
					<el-button class="mini" @click="binding_bankcard(scope.row,1)" size="mini" type="text">
						绑卡
					</el-button>
					<el-button class="btn_left" @click="invoicing_ord_cancel(scope.row,1)" size="mini" type="text">
						撤销开票申请
					</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>
		<el-backtop
		target=".page"
		:bottom="100"
		:visibility-height="50"
		></el-backtop>
		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 100, 200, 500]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>
		<!-- 运单删除原因 -->
		<el-dialog
				title="运单删除原因"
				:visible.sync="set_act_delete.is_show"
				width="300px"
				top="30vh"
				@close="tord_delete_close"
			>
			<el-select v-model="set_act_delete.show_msg" placeholder="请选择删除原因">
				<el-option label="错误接单" value="错误接单"></el-option>
				<el-option label="运输取消" value="运输取消"></el-option>
			</el-select>
			<el-input class="el_input" style="margin-top: 10px;" v-model="set_act_delete.show_msg" placeholder="请输入运单删除原因" clearable></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="set_act_delete.is_show = false">取 消</el-button>
				<el-button type="primary" @click="tord_act_delete">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog top="1vh" width="90%" :visible.sync="xieyi.is_show">
			<el-button type="primary" @click="xieyi_up(xieyi.base64,'运输协议')">下载</el-button>
		<div class="iframe-container" v-if="xieyi.is_show">
			<iframe
			:src="xieyi.url"
			frameborder="0"
			allowfullscreen
			></iframe>
		</div>
		</el-dialog>
		<!-- 运单删除原因 -->
		<el-dialog
				title="批量填写发票备注"
				:visible.sync="batch_mark_show"
				width="30%"
				top="30vh"
				@close="tord_delete_close"
			>
			<el-input
				type="textarea"
				:rows="2"
				placeholder="请输入发票备注"
				v-model="invoice_mark">
			</el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="batch_mark_show = false">取 消</el-button>
				<el-button type="primary" @click="batch_batch_mark_sub">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 垫资信息 -->
		<el-dialog
				title="垫资信息"
				:visible.sync="yufu_info.is_show"
				width="30%"
				top="30vh"
			>
			<div>
				<div>
					审核状态：{{yufu_info.yufu_status_text}}
				</div>
				<!-- <div v-if="yufu_info.yufu_apply_time_text">
					垫资申请时间：{{yufu_info.yufu_apply_time_text}}
				</div> -->
				<div v-if="yufu_info.yufu_creat_time_text">
					垫资申请时间：{{yufu_info.yufu_creat_time_text}}
				</div>
				<div v-if="yufu_info.yufu_pass_time_text">
					垫资审核通过时间：{{yufu_info.yufu_pass_time_text}}
				</div>
				<div v-if="yufu_info.yufu_unpass_time_text">
					垫资审核打回时间：{{yufu_info.yufu_unpass_time_text}}
				</div>
				<div v-if="yufu_info.yufu_fail_reason">
					垫资审核打回原因：{{yufu_info.yufu_fail_reason}}
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="yufu_info.is_show = false">取 消</el-button>
				<el-button type="primary" @click="yufu_info.is_show = false">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 搜索表单 -->
		<serForm 
			:is_show="ser_form_is_show" 
			:form="form" 
			@sub="ser_form_sub"
			@close="ser_para_clear"
		></serForm>

		<!-- 运单修改界面 -->
		<tordEdit :tord_info="edit.tord_info" :is_show="edit.is_show" @sub="tord_edit_sub"></tordEdit>

		<!-- 凭证界面 -->
		<voucher :truck_tord_num="voucher.truck_tord_num" :is_show="voucher.is_show"></voucher>

		<!-- 已结算列表界面 -->
		<settleList 
			:tord_info="settle.tord_info" 
			:is_show="settle.is_show"
			@settle_list_close="settle_view_close"
		></settleList>

		<!-- 运费修改界面 -->
		<freightEdit 
			@edited="freight_edit_close"
			:tord_info="freight_edit.tord_info" 
			:is_show="freight_edit.is_show"
		></freightEdit>

		<!-- 货车轨迹 -->
		<trucktTrajectory 
			v-if="trajectory.is_show" 
			:case_county="trajectory.case_county" 
			:aim_county="trajectory.aim_county" 
			:start_time="trajectory.start_time" 
			:end_time="trajectory.end_time" 
			:truck_plate_num="trajectory.truck_plate_num"
			:driver_info="trajectory.driver_info"
		    :aim_lngLnt="trajectory.aim_lngLnt"
			:case_lngLnt="trajectory.case_lngLnt"
			:truck_tord_num="trajectory.truck_tord_num"
			@close="truck_trajectory_close" 
		></trucktTrajectory>

		<!-- 运单锁定 -->
		<tordLock 
			:is_show="lock.is_show" 
			@close="tord_lock_close" 
		></tordLock>

		<!-- 导出搜索结果 -->
		<serRelExport 
			:is_show="tord_export.is_show" 
			:rull="tord_export.rull" 
			@close="tord_export_close" 
		></serRelExport>

		<!-- 上报记录 -->
		<uplList 
			:is_show="upl_list.is_show" 
			:tord_from="upl_list.tord_from" 
			:tord_info="upl_list.tord_info" 
		></uplList>

		<!-- 运单参数 -->
		<tordSet 
			:is_show="tord_set.is_show" 
			:truck_tord_num="tord_set.truck_tord_num" 
		></tordSet>

		<!-- 协议查看 -->
		<xieYi 
			:is_show="agreement.is_show" 
			:data='agreement.data'
		></xieYi>
		<!-- 运单锁定原因 -->
		<el-dialog title="运单锁定原因" top="1vh" width="600px" :visible.sync="lock.is_show1">
			<div>{{lock.lock_reason}}</div>
		</el-dialog>

		<el-dialog title="运单删除原因" top="1vh" width="600px" :visible.sync="delete1.is_show">
			<div>{{delete1.lock_reason}}</div>
		</el-dialog>
		<!-- 收款人设置 -->
		<setTruckOwnerInfo
			:is_show="truck_owner_info.is_show"
			:data ="truck_owner_info.data"
			:check_info="truck_owner_info.check_info"
			@edited="get_page_data"
		></setTruckOwnerInfo>

		<!-- 垫资支付 -->
		<tordPay
		 :is_show="tord_pay.is_show"
		 :data="tord_pay.data"
		>
		</tordPay>

		    <!-- 支付记录页面 -->
		<Payment
		:is_show="Payment.is_show"
		:truck_tord_num="Payment.truck_tord_num"
		></Payment>

		<!-- 绑定弹窗 -->
		<bind
			:is_show="bank_card.is_show"
			:data="bank_card.data"
		></bind>

	    <!-- 司机详情 -->
		<driverInfo
			:is_show="driver_info.is_show" 
			:driver_info="driver_info.data" 
		></driverInfo>

		<!-- 货车详情 -->
		<truckInfo
			:is_show="truck_info.is_show" 
			:truck_info="truck_info.data" 
		></truckInfo>

		<!-- 修改记录 -->
		<editRecord
			:is_show="edit_record.is_show"
			:data="edit_record.data"
		></editRecord>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import serForm from './ser_form.vue'
	import tordEdit from './edit.vue'
	import tordSet from './tord_set.vue'
	import voucher from '../../voucher/voucher_before_end.vue'
	import settleList from './settle/list.vue'
	import uplList from '../upl/upl_list.vue'
	import tordLock from './lock.vue'
	import serRelExport from './ser_rel_export.vue'
	import freightEdit from './freight_edit.vue'
	import xieYi from './xieyi1.vue'
	import trucktTrajectory from '@/components/map/amap/trajectory.vue'
	import { Loading } from 'element-ui';
	import setTruckOwnerInfo from './set_truck_owner_info.vue'
	import tordPay from './tord_pay.vue'
	import Payment from "./pay_list.vue";
	import bind from './bind.vue'
	import driverInfo from './app_user_info/driver_info.vue'
	import truckInfo from './app_user_info/truck_info.vue'
	import editRecord from './edit_record.vue'
	export default {
		components:{
			serForm,
			tordEdit,
			tordSet,
			voucher,
			settleList,
			uplList,
			tordLock,
			serRelExport,
			freightEdit,
			trucktTrajectory,
			xieYi,
			setTruckOwnerInfo,
			tordPay,
			Payment,
			bind,
			driverInfo,
			truckInfo,
			editRecord
		},
		data() {
			return {
				xieyi:{
					is_show:false,
					url:'',
					base64:'',
				},
				is_show_tel:['19155606858'],//该手机号不显示运单列表收款人
				class_hide:true,
				date_value:'',
				invoice_time:'',
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				total:'',//总计费用
				num_a:1,
				//搜索界面是否打开
				ser_form_is_show:0,
				invoice_mark:"",//发票备注
				//搜索条件
				form: {				
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游编号
					shipper_tel:'',//货主手机号
					shipper_tel_not:'',//非货主手机号
					shipper_company_name:'',//货主公司名称
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					status:'',//状态
					cargo_name:'',//货物名称
					payed_status:'',//支付状态
					settled_status:'',//结算状态
					upl_status:'',//上报状态
					is_set_freight:'',//运费设置
					bl_name:'',//线路名称搜索
					i_status:'',//开票状态
					area_id:'',//大区搜索
					saler_tel:'',//销售电话搜索
					check_act_status:'',//验证状态搜索
					start_time_start:0,
					arrived_time_end:0,

					invoiced_time_start:'', //开票开始时间
                    invoiced_time_end:'',//开票结束时间

					is_show:'',//运单申请删除

					creat_time_arr:[],//建单时间

					insure_time_arr:[],//邮件发送时间

					logink_up_time_arr:[],//监管上报时间

					id:'',
					voucher_status:'',//回单是否上传

					etc_status:'',//etc开票状态

					item_name:'',//项目名称

					logink_up_status:'',

					is_set_insure:'',//保障服务费
					
					insure_status:'',//有无发送保险  有无购买保险

					is_yufu:'',//是否垫资

					is_admin_pay_return:'',//是否回款

					lock_status:'',
				},
				creat_time_arr1:'',
				insure_time_arr:'',//邮件发送时间
				logink_up_time_arr:'',
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
					total_freight:'',//总金额
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//银行卡绑定
				bank_card:{
					is_show:0,
					data:null,
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:'',
				},

				//结算界面
				settle:{
					is_show:0,
					tord_info:{}
				},

				//修改相关
				edit:{
					is_show:0,
					tord_info:{}
				},
				
				//运费修改相关
				freight_edit:{
					is_show:0,
					tord_info:{}
				},

				//运单上报记录
				upl_list:{
					is_show:0,
					tord_from:'act',
					tord_info:{}
				},

				//货车轨迹
				trajectory:{
					is_show:false,
					case_county:null,
					aim_county:null,
					start_time:null,
					end_time:null,
					truck_plate_num:null,
					aim_lngLnt:{},
					case_lngLnt:{},
					driver_info:{},
					truck_tord_num:'',
				},

				//运单锁定界面
				lock:{
					is_show:0,
					truck_tord_num:'',
				},

				//导出搜索结果界面
				tord_export:{
					is_show:0,
					rull:{}
				},
				
				//运单参数界面
				tord_set:{
					is_show:0,
					truck_tord_num:'',
				},
				restaurants: [],
				
				//选中的运单
				tords_choosed:[],

				//运单删除原因弹窗
				set_act_delete:{
					is_show:false,
					show_msg:'',//运单取消原因
					truck_tord_num:'',
				},
				//协议弹窗
				agreement:{
					is_show:0,
					data:'',
				},

				//设置收款人
				truck_owner_info:{
					is_show:0,
					data:null,
					check_info:'',
				},
				num:0,
				batch_mark_show:false,
				//运单锁定原因
				lock:{
					is_show:false,
					lock_reason:'',
					is_show1:false
				},
				//运单删除原因
				delete1:{
					is_show:false,
					lock_reason:'',
				},
				//垫资支付数据
				tord_pay:{
					is_show:0,//
					data:'',
				},
				Payment: {
					is_show: 0,
					truck_tord_num: "",
				},
				//垫资相关
				yufu_info:{
					is_show:false,
					yufu_status_text:'',
					yufu_fail_reason:'',
					yufu_creat_time_text:'',
					yufu_pass_time_text:'',
					yufu_unpass_time_text:'',
				},
				batch_lock_msg:'',//批量锁定原因

				//司机详情
				driver_info:{
					data:{},
					is_show:0,
				},
				//车辆详情
				truck_info:{
					data:{},
					is_show:0,
				},
				edit_record:{
					is_show:0,
					data:[]
				}
			}
		},
		created() {
			//取出参数
			let query=this.$route.query;
			for(var i in query){
				this.form[i]=query[i]
			}
			if(this.is_show_tel.indexOf(this.user_info.tel)!=-1){
				this.class_hide =false
			}
			//读取货源
			this.get_page_data()
		},
		computed:{
			...mapState(['logink_aim_list','user_info'])
		},
		methods: {
			//运单修改记录
			tord_edit_record(item){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'ord_edit_record',
						truck_tord_num:item.truck_tord_num,
					},callback:(data)=>{
						this.edit_record.data = data.list
						this.edit_record.is_show ++
					}
				})
			
			},
			//车辆详情
			truck_info_open(item){
				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_list_by_admin',
						is_get_app_user_info:1,
						truck_plate_num:item.truck_plate_num
					},
					callback:(data)=>{
						this.truck_info.data=data.list[0]

						//打开弹出层
						this.truck_info.is_show++;
					}
				})
			},
			//司机详情
			driver_info_open(item){
				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_list_by_admin',
						user_tel:item.driver_info.tel
					},
					callback:(data)=>{
						//置入数据
						this.driver_info.data=data.list[0]

						//打开弹出层
						this.driver_info.is_show++;
					}
				})
			},
			//批量取消/审核删除
			batch_cancel_delete(n,type){
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				Loading.service(this.options);
				//关闭等待特效
				if(n==this.tords_choosed.length){
					this.close_loading()
					this.$my.other.msg({
						type:"success",
						str:'批量取消删除成功'
					});
					this.batch_delete_msg = ''
					//刷新本页
					this.get_page_data()
					return
				}
				//提交
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'set_tord_hide_admin',
						truck_tord_nums:[choosed[n].truck_tord_num],
						action_status:type
					},
					callback:(data)=>{
						if(data.code!=0){
							this.$my.other.msg({
								type:"info",
								str:data.error_info
							});
							this.close_loading()
							return
						}
						n++
						this.batch_cancel_delete(n,type)
					},
				});
				
			},
			batch_delete_open_msg(n){
				this.$prompt('请输入删除原因', {
					}).then(({ value }) => {
						this.batch_delete_msg = value
						this.batch_delete(n)  
					}).catch(() => {
						 
				});
			},
			//批量删除
			batch_delete(n){
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				Loading.service(this.options);
				//关闭等待特效
				if(n==this.tords_choosed.length){
					this.close_loading()
					this.$my.other.msg({
						type:"success",
						str:'批量删除成功'
					});
					this.batch_delete_msg = ''
					//刷新本页
					this.get_page_data()
					return
				}
				//提交
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'set_tord_hide',
						truck_tord_nums:[choosed[n].truck_tord_num],
						type:4,
						show_msg:this.batch_delete_msg
					},
					callback:(data)=>{
						if(data.code!=0){
							this.$my.other.msg({
								type:"info",
								str:data.error_info
							});
							this.close_loading()
							return
						}
						n++
						this.batch_delete(n)
					},
				});
			},
			//银行卡绑定
			binding_bankcard(data){
				
				this.bank_card.is_show ++
				this.bank_card.data = data
			},
			//协议下载
			xieyi_up(url,name){
				let link = document.createElement('a');
				link.href = 'data:application/pdf;base64,'+url;

				//对下载的文件命名
				link.download = name;

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			//批量解锁
			batch_unlock(n){
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				Loading.service(this.options);
				//关闭等待特效
				if(n==this.tords_choosed.length){
					this.close_loading()
					this.$my.other.msg({
						type:"success",
						str:'批量解锁成功'
					});
					//刷新本页
					this.get_page_data()
					return
				}
				//提交
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_unlock_by_admin',
						truck_tord_num:choosed[n].truck_tord_num,
					},
					callback:(data)=>{
						if(data.code!=0){
							this.$my.other.msg({
								type:"info",
								str:data.error_info
							});
							return
						}
						n++
						this.batch_unlock(n)
					},
				});
			},
			batch_lock_open_msg(n){
				this.$prompt('请输入驳回原因', {
					}).then(({ value }) => {
						this.batch_lock_msg = value
						this.batch_lock_open(n)  
					}).catch(() => {
						 
				});
			},


			//批量锁定
			batch_lock_open(n){
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				Loading.service(this.options);
				//关闭等待特效
				if(n==this.tords_choosed.length){
					this.close_loading()
					this.$my.other.msg({
						type:"success",
						str:'批量锁定成功'
					});
					//刷新本页
					this.get_page_data()
					return
				}
				//提交
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_lock_by_admin',
						lock_reason:this.batch_lock_msg.trim(),
						truck_tord_num:choosed[n].truck_tord_num,
					},
					callback:(data)=>{
						if(data.code!=0){
							this.$my.other.msg({
								type:"info",
								str:data.error_info
							});
							return
						}
						n++
						this.batch_lock_open(n)
					},
				});

			},
			//打卡垫资信息
			yufu_info_open(item){
				this.yufu_info.is_show=true
				//预付状态
				switch(item.yufu_status){
					case '1':this.yufu_info.yufu_status_text="待审核";break;
					case '2':this.yufu_info.yufu_status_text="审核通过";break;
					case '3':this.yufu_info.yufu_status_text="审核打回";break;
				}
				//垫资申请时间
				if(item.yufu_creat_time>0){
					this.yufu_info.yufu_creat_time_text =  this.$my.other.totime(item.yufu_creat_time);
				}
				//垫资审核通过时间
				if(item.yufu_pass_time>0){
					this.yufu_info.yufu_pass_time_text =  this.$my.other.totime(item.yufu_pass_time);
				}
				//垫资审核打回时间
				if(item.yufu_unpass_time>0){
					this.yufu_info.yufu_unpass_time_text =  this.$my.other.totime(item. yufu_unpass_time);
				}
				//垫资审核打回原因
				if(item.yufu_fail_reason){
					this.yufu_info.yufu_fail_reason =  item.yufu_fail_reason
				}
				console.log(this.yufu_info)
			},
			// 打开支付记录
			tord_payed_list1(item) {
				this.Payment.truck_tord_num = item.truck_tord_num;
				this.Payment.is_show++;
			},
			//垫资支付
			tord_pay_creat_by_admin(data){
				this.tord_pay.data = data
				this.tord_pay.is_show++
			},
			//线路更换 //同步
			tord_bl_edit_by_shipper(item,type){
				//线路同步
				if(type ==1){
					 this.$prompt('请输入新的线路编号', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						}).then(({ value }) => {
							this.$my.net.req({
								data:{
									mod:'truck_tord_real_time',
									ctr:'tord_bl_edit_by_admin',
									truck_tord_num:item.truck_tord_num,
									bl_num:value
								},callback:(data)=>{
									this.$my.other.msg({
										type:"success",
										str:'操作成功'
									});

									//刷新
									this.get_page_data()
								}
							})
						}).catch(() => {   
					});
				}else{
					this.$my.net.req({
						data:{
							mod:'truck_tord_real_time',
							ctr:'tord_bl_edit_by_admin',
							truck_tord_num:item.truck_tord_num,
							bl_num:item.bl_num
						},callback:(data)=>{
							this.$my.other.msg({
								type:"success",
								str:'操作成功'
							});

							//刷新
							this.get_page_data()
						}
					})
				}
			},
			//查看运单锁定原因
			tord_lock_reason_show(item){
				this.lock.is_show1=true;
				this.lock.lock_reason=item.lock_reason;
			},
			//查看运单锁定原因
			tord_lock_delete_show(item){
				this.delete1.is_show=true;
				this.delete1.lock_reason=item.show_msg;
			},
			//批量清除
			batch_set_insure_status(num){
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				Loading.service(this.options);
				var truck_tord_num = []
				this.tords_choosed.forEach(item=>{
					truck_tord_num.push(item.truck_tord_num)
				})
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'set_insure_status',
						truck_tord_num,
					},callback:(data)=>{
						this.$my.other.msg({
							str:'操作成功',
							type:'success'
						});
						this.close_loading()
						//刷新本页
						this.get_page_data()
						return
					}
				})
			},
			//邮件清除
			set_insure_status(item){
				//提示
				this.$my.other.confirm({
					content:"是否清除此运单邮件?",
					confirm:()=>{
							
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'set_insure_status',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
							
								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							},
						});
					},
				});
			},
			//收款人设置
			set_truck_owner(){
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				let tick_freight = 0
				this.tords_choosed.forEach(item=>{
					tick_freight += Number(item.cost_info.freight_total) * 100
				})
				this.truck_owner_info.data = this.tords_choosed
				this.truck_owner_info.check_info = '当前勾选总条数'+this.tords_choosed.length + '' +'总运费'+tick_freight+'元(不含服务费)'
				this.truck_owner_info.is_show ++
			},
			//批量发送邮件
			set_tord_mail(){
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				var truck_tord_num = []
				this.tords_choosed.forEach(item=>{
					truck_tord_num.push(item.truck_tord_num)
				})
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'set_tord_insure',
						truck_tord_num,
					},callback:(data)=>{
						this.$my.other.msg({
							str:'操作成功',
							type:'success'
						});
						this.get_page_data()
					}
				})
			},
			batch_batch_mark_sub(){
				var truck_tord_nums = []
				this.tords_choosed.forEach(item=>{
					truck_tord_nums.push(item.truck_tord_num)
				})
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_mark',
						truck_tord_nums,
						invoice_mark:this.invoice_mark
					},callback:(data)=>{
						this.$my.other.msg({
							str:'操作成功',
							type:'success'
						});
						this.batch_mark_show =false
						this.invoice_mark = ''
						//刷新
						this.get_page_data()
					}
				})
			},
			//批量填写运单备注
			batch_mark(){
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				this.batch_mark_show = true
			},
			//凭证下载
			 voucher_download(url,name) {
					let image = new Image();
					image.setAttribute('crossOrigin', 'anonymous');
					image.src = url;
					image.onload = () => {
						let canvas = document.createElement('canvas');
						canvas.width = image.width;
						canvas.height = image.height;
						let ctx = canvas.getContext('2d');
						ctx.drawImage(image, 0, 0, image.width, image.height);
						canvas.toBlob((blob) => {
						let url = URL.createObjectURL(blob);
						this.download(url,name);
						// 用完释放URL对象
						URL.revokeObjectURL(url);
						});
					};
				},
			download(href,name) {
					let eleLink = document.createElement('a');
					eleLink.download = name;
					eleLink.href = href;
					eleLink.click();
					eleLink.remove();
			},
			//关闭下载等待特效
			close_loading(){
				let loadingInstance = Loading.service(this.options);
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
				loadingInstance.close();
				});
			},
			//回单批量下载
			batch_download_voucher(){
				Loading.service(this.options);
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				//关闭等待特效
				if(this.num==this.tords_choosed.length){
					this.close_loading()
					this.num = 0
					return
				}
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_voucher_list',
						truck_tord_num:choosed[this.num].truck_tord_num
					},callback:(data)=>{
						if(data.msg.list.length ==0){
							this.num ++ 
							this.batch_download_voucher()
							return
						}
						if(data.code ==0){
							let nums = 0
							var name = ''
							data.msg.list.forEach(item=>{
									switch(item.upl_user_type){
									case '1':var upl_type_text="货主上传";break;
									case '2':var upl_type_text="司机上传";break;
									case '3':var upl_type_text="后台上传";break;
									case '4':var upl_type_text="货主子账号上传";break;
									case '5':var upl_type_text="网点上传";break;
								}
								switch(item.type){
									case '1':var type_text="磅单";break;
									case '2':var type_text="回单";break;
								}
								name = choosed[this.num].truck_tord_num + '-'+upl_type_text + '-' +type_text
								var url =this.$my.qiniu.make_src('trucktordrealtimevoucher', item.img_key)
								this.voucher_download(url,name)
								nums ++
								if(nums == data.msg.list.length){
									this.num ++ 
									this.batch_download_voucher()
								}
								
							})
						}
					}
				})
			},
			//异常运单初始化
			tord_abnormal_init(){
				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}
				var truck_tord_nums = []
				this.tords_choosed.forEach(item=>{
					truck_tord_nums.push(item.truck_tord_num)
				})
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'abnormal_act_init',
						truck_tord_nums
					},callback:(data)=>{
						this.$my.other.msg({
							str:'操作成功',
							type:'success'
						});
						//刷新
						this.get_page_data()
					}
				})
			},
			trajectory_check(item,num_a){
				var num  = num_a 
				if(num  == this.tords_choosed.length){
					return
				}
				num ++
				//查询轨迹
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck',
						ctr:'truck_trajectory',
						truck_plate_num:item.truck_plate_num,
						start_time:item.start_time,
						end_time:item.arrived_time,
					},callback:(res)=>{

						if(res.code !=0){
							item.text = res.error_info
						}
						
						this.tord_abnormal_select1(num)

					}
				})
			},
			//异常轨迹运单 更新版
			tord_abnormal_select1(num){
				var num_a = 0
				
				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}
				if(Number(num)){num_a =num}
				if(!this.tords_choosed[num_a].case_other_obj.prov_code){
					this.tords_choosed[num_a].text = '未查询到发货区划代码'
				}
				if(!this.tords_choosed[num_a].aim_other_obj.prov_code){
					this.tords_choosed[num_a].text = '未查询到到货区划代码'
				}
					this.trajectory_check(this.tords_choosed[num_a],num_a)
				
			},
			//异常运单筛选
			tord_abnormal_select(){
				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}
				var truck_tord_nums = []
				this.tords_choosed.forEach(item=>{
					truck_tord_nums.push(item.truck_tord_num)
				})
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'abnormal_act',
						truck_tord_nums
					},callback:(data)=>{
						this.$my.other.msg({
							str:'操作成功',
							type:'success'
						});
						//刷新
						this.get_page_data()
					}
				})
			},
			//协议查看
			show_agreement(item){
				if(item.truck_tord_num){
                    this.$my.net.req({
						take_over_control:1,
                        data:{
                            mod:'res',
                            ctr:'driveragreement_api',
                            truck_tord_num_act:item.truck_tord_num
                        },callback:(data)=>{
							if(data.code ==2){
								this.xieyi.url = data.msg.url
								this.xieyi.base64 = data.msg.base64
                            	this.xieyi.is_show = true
							}else{
								this.agreement.is_show++
								this.agreement.data =item
							}
                        }
                    })
                }else{
                    this.$my.net.req({
						take_over_control:1,
                        data:{
                            mod:'res',
                            ctr:'driveragreement_api',
                            bl_num:item.data.bl_num
                        },callback:(data)=>{
							if(data.code ==2){
								this.xieyi.url = data.msg.url
                            	this.xieyi.is_show = true
							}else{
								this.agreement.is_show++
								this.agreement.data =item
							}
                        }
                    })
                }
			},
			invoicing_ord_cancel(item){
				//提示
				this.$my.other.confirm({
					content:"是否撤销开票申请?",
					confirm:()=>{
							
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'invoicing_ord_cancel',
								truck_tord_num:item.truck_tord_num,

							},
							callback:(data)=>{
							
								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							},
						});
					},
				});
			},
			//运单审核删除
			act_delete(item,num){
				var tisp = ''
				if(num ==1){
					tisp = '取消删除'
				}else{
					tisp = '确定删除'
				}
				//提示
				this.$my.other.confirm({
					content:"您的操作是" + tisp,
					confirm:()=>{
							
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'set_tord_hide_admin',
								truck_tord_nums:[item.truck_tord_num],
								action_status:num

							},
							callback:(data)=>{
							
								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							},
						});
					},
				});
			},
			//勾选
			tords_list_choose(event){
				//置入
				this.tords_choosed=event
			},
			
			tords_freight_check(){
				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}
				
				//汇集未支付
				let tord_arr=[];
				for(var item of this.tords_choosed){
					if(item.payed_status==1&&item.settlement_type==1){
						tord_arr.push(item.truck_tord_num);
					}
				}

				if(tord_arr.length==0){
					this.$my.other.msg({
						str:'缺少符合条件的运单(已支付，切按吨结算)',
						type:'failed'
					});
				}
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'批量重新核对并修改运费',请确认",
					confirm:()=>{
							
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'set_tord_cost_admin',
								truck_tord_nums:tord_arr,
							},
							callback:(data)=>{
							
								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			bl_focus(){
				//格式判断
				if(!this.$my.check.is_tel(this.form.shipper_tel)){
					this.$my.other.msg({
						type:"warning",
						str:'货主电话号码格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						tel:this.form.shipper_tel
					},
					callback:(data)=>{
						data=data.list[0];
						if(data.name=='未实名'){
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}
						if(!data.user_num){
							this.$my.other.msg({
								type:"warning",
								str:'缺少货主信息'
							});
							return;
						}
						//去服务器读取线路
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_real_time_bl_list_admin',
								shipper_num:data.user_num,
							},
							callback:(data)=>{
								var bl_list = []
								//遍历
								for(var item of data.list){

									//结算方式
									var settlement_type_text=""
									switch(item.is_need_return){
										case '1':settlement_type_text="按吨数结算";break;
										case '2':settlement_type_text="按方数结算";break;
										case '3':settlement_type_text="按趟结算";break;
									}

									//是否是双边
									var is_need_return_text=""
									switch(item.is_need_return){
										case '1':is_need_return_text="双边";break;
										case '2':is_need_return_text="单边";break;
									}

									//装货地其他数据
									item.case_other_obj=JSON.parse(item.case_other);

									//卸货地其他数据
									item.aim_other_obj=JSON.parse(item.aim_other);

									//汇集路线数据
									item.info_text=item.case_prov+'/'+item.case_city+'/'+item.case_county+' ---- '+item.aim_prov+'/'+item.aim_city+'/'+item.aim_county+' ('+is_need_return_text+') ('+settlement_type_text+')'
									bl_list.push({
										value:item.bl_name,
										address:item.bl_name
									})
								}
								this.restaurants = bl_list
							}
						})
					}
				});
			},
		   querySearch(queryString, cb) {
				var restaurants = this.restaurants;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			//轨迹
			truck_trajectory_open(item){
				if(item.status==1){
					this.$my.other.msg({
						type:'warning',
						str:'此运单未发车,不能查看轨迹'
					})
					return;
				}
				// this.trajectory.truck_plate_num = item.truck_plate_num
				// if(item.start_time){
				// 	this.trajectory.creat_time_text = item.creat_time_text
				// }else{
				// 	this.trajectory.creat_time_text = '未发车'
				// }
				// if(item.arrived_time_text){
				// 	this.trajectory.arrived_time_text = item.arrived_time_text
				// }else{
				// 	this.trajectory.arrived_time_text = '未到货'
				// }
				this.trajectory.driver_info = item.driver_info
				this.trajectory.truck_tord_num = item.truck_tord_num
				this.trajectory.case_county=item.case_county;
				this.trajectory.case_lngLnt={lat:item.case_lat,lon:item.case_lng}
				this.trajectory.aim_lngLnt={lat:item.aim_lat,lon:item.aim_lng};
				this.trajectory.aim_county=item.aim_county;
				this.trajectory.start_time=parseInt(item.start_time);
				this.trajectory.end_time=item.status==2?parseInt(new Date().getTime()/1000):parseInt(item.arrived_time);
				this.trajectory.truck_plate_num=item.truck_plate_num;
				this.trajectory.is_show=true;
			},
			truck_trajectory_close(){
				this.trajectory.is_show=false;
				this.trajectory.case_county=null;
				this.trajectory.aim_county=null;
				this.trajectory.start_time=null;
				this.trajectory.end_time=null;
				this.trajectory.truck_plate_num=null;
			},

			//打开运单参数界面
			tord_set_open(item){

				//置入运单编号
				this.tord_set.truck_tord_num=item.truck_tord_num

				//打开
				this.tord_set.is_show++
			},

			//上报记录
			tord_upl_open(item){
				this.upl_list.tord_info={
					truck_tord_num:item.truck_tord_num,
					truck_plate_num:item.truck_plate_num,
					upl_status:item.upl_status,
					start_time:item.start_time,
					arrived_time:item.arrived_time,
					case_code:item.case_other_obj.county_code,
					aim_code:item.aim_other_obj.county_code,
				}
				this.upl_list.is_show++
			},

			//运单锁定相关
			tord_lock_open(item){//打开运单锁定界面
				this.lock.truck_tord_num=item.truck_tord_num
				this.lock.is_show++
			},
			tord_lock_close(obj){//关闭运单锁定界面
				
				//锁定原因
				if(!obj.lock_reason.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少锁定原因'
					});
					return;
				}

				//提交
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_lock_by_admin',
						lock_reason:obj.lock_reason.trim(),
						truck_tord_num:this.lock.truck_tord_num,
					},
					callback:(data)=>{

						this.$my.other.msg({
							type:"success",
							str:'锁定成功'
						});

						//关闭弹出层
						this.lock.is_show=0
	
						//刷新本页
						this.get_page_data()
					},
				});
			},
			tord_unlock(item){//运单解锁

				//提示
				this.$my.other.confirm({
					content:"您的操作是'运单解锁',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_unlock_by_admin',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
			
								this.$my.other.msg({
									type:"success",
									str:'锁定成功'
								});

								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//运费相关操作
			freight_edit_open(item){//打开运费界面
				this.freight_edit.is_show++;
				this.freight_edit.tord_info={...item}
			},
			freight_edit_close(){//关闭运费界面
				this.freight_edit.is_show=0
				this.freight_edit.tord_info={}

				//刷新
				this.get_page_data()
			},

			//前往支付记录
			tord_payed_list(item,aim){
				
				//跳转
				this.$router.push({
					path:'/pages/transit_real_time/'+aim,
					query:{
						truck_tord_num:item.truck_tord_num
					}
				});
			},

			//打开结算界面
			settle_view_open(item){

				//置入运单信息
				this.settle.tord_info=item

				//打开结算界面
				this.settle.is_show++;
			},
			settle_view_close(){

				//关闭结算界面
				this.settle.is_show=0;

				//清空运单信息
				this.settle.tord_info={}
			},

			//完结相关操作
			tord_edit_sub(item){
				
				//关闭弹出层
				this.edit.is_show=0;

				//刷新
				this.get_page_data()
			},
			tord_edit(item){
				this.edit.is_show++;
				this.edit.tord_info=item
			},
			
			//凭证相关操作
			voucher_show(item){//显示凭证
				this.voucher.is_show++;
				this.voucher.weight={
					cargo_weight_start:item.cargo_weight_start,
					cargo_weight_arrived:item.cargo_weight_arrived,
					cargo_weight:item.cargo_weight
				}
				this.voucher.truck_tord_num=item.truck_tord_num
			},
			tord_delete_close(){
				this.set_act_delete.show_msg=''
				this.set_act_delete.truck_tord_num = ''
			},
			//运单删除
			tord_act_delete(){
					this.$my.other.confirm({
					content:"是否确认提交删除?",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'set_tord_hide',
								truck_tord_nums:[this.set_act_delete.truck_tord_num],
								type:4,
								show_msg:this.set_act_delete.show_msg
							},callback:(res)=>{
								this.$my.other.msg({
									str:'提交成功',
									type:'success'
								});
								this.get_page_data(),
								this.set_act_delete.show_msg=''
								this.set_act_delete.is_show = false
								this.set_act_delete.truck_tord_num = ''
							}
						})
					}
				});
			},
			//运单删除
			tord_delete(item){
				this.set_act_delete.is_show = true
				this.set_act_delete.truck_tord_num = item.truck_tord_num
			},
			//取消运单
			// tord_cancel(item){
			// 	 this.$prompt("您的操作是'取消运单',请输入取消原因后确认", {
			// 		confirmButtonText: '确定',
			// 		cancelButtonText: '取消',
			// 		}).then(({ value }) => {
			// 			this.$my.net.req({
			// 				data:{
			// 					mod:'truck_tord_real_time',
			// 					ctr:'tord_act_cancel_by_admin',
			// 					truck_tord_nums:[item.truck_tord_num],
			// 					end_msg:value,
			// 				},
			// 				callback:(data)=>{
			                   
			// 					//刷新本页
			// 					this.get_page_data()
			// 				},
			// 			});
			// 		}).catch(() => {
			// 			this.$message({
			// 				type: 'info',
			// 				message: '取消输入'
			// 			});       
			// 		});

			// 	//提示
			// 	// this.$my.other.confirm({
			// 	// 	showInput:true,
			// 	// 	content:"您的操作是'取消运单',请确认",
			// 	// 	confirm:()=>{
			
			// 	// 		//提交
			// 	// 		this.$my.net.req({
			// 	// 			data:{
			// 	// 				mod:'truck_tord_real_time',
			// 	// 				ctr:'tord_act_cancel_by_admin',
			// 	// 				truck_tord_nums:[item.truck_tord_num],
			// 	// 			},
			// 	// 			callback:(data)=>{
			
			// 	// 				//刷新本页
			// 	// 				this.get_page_data()
			// 	// 			},
			// 	// 		});
			// 	// 	}
			// 	// });
			// },
			
			//确认到货
			tord_arrived(item){
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'确认到货',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_arrived_by_admin',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//确认发车
			tord_start(item){
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'确认发车',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_start_by_admin',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//更多条件
			ser_form_open(){//打开

				this.ser_form_is_show++
			},
			ser_form_sub(obj){//提交
				//置入表单数据
				for(var key in obj){

					if(!obj[key]){
						continue;
					}

					// this.form[key]=obj[key];
					//特殊处理
					switch(key){

						case 'cargo_creat_time'://货源创建时间
							this.form.cargo_creat_time_arr=[
								(obj.cargo_creat_time[0].getTime() / 1000).toFixed(0),
								(obj.cargo_creat_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'creat_time'://成交时间(运单创建时间)
							this.form.creat_time_arr=[
								(obj.creat_time[0].getTime() / 1000).toFixed(0),
								(obj.creat_time[1].getTime() / 1000).toFixed(0),
							];break;
						case 'start_time'://发车时间
							this.form.start_time_arr=[
								(obj.start_time[0].getTime() / 1000).toFixed(0),
								(obj.start_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'arrived_time'://到货时间
							this.form.arrived_time_arr=[
								(obj.arrived_time[0].getTime() / 1000).toFixed(0),
								(obj.arrived_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'payed_time'://支付完成时间
							this.form.payed_time_arr=[
								(obj.payed_time[0].getTime() / 1000).toFixed(0),
								(obj.payed_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'invoiced_time'://开票完成时间
							this.form.invoiced_time_arr=[
								(obj.invoiced_time[0].getTime() / 1000).toFixed(0),
								(obj.invoiced_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'settled_time'://结算完成时间
							this.form.settled_time_arr=[
								(obj.settled_time[0].getTime() / 1000).toFixed(0),
								(obj.settled_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'tord_upld_time'://运单上报时间
							this.form.tord_upld_time_arr=[
								(obj.tord_upld_time[0].getTime() / 1000).toFixed(0),
								(obj.tord_upld_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'outcash_upld_time'://出金流水上报时间
							this.form.outcash_upld_time_arr=[
								(obj.outcash_upld_time[0].getTime() / 1000).toFixed(0),
								(obj.outcash_upld_time[1].getTime() / 1000).toFixed(0),
							];break;
					}
				}
				this.form.lock_status = obj.lock_status
				this.form.out_trade_num = obj.out_trade_num
				//关闭页面
				this.ser_form_is_show=0
				//读取数据
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1
				if (this.date_value) {
					this.form.start_time_start = parseInt(this.date_value[0] / 1000);
					this.form.arrived_time_end= parseInt(this.date_value[1] / 1000);
				} else {
					this.form.start_time_start = "";
					this.form.arrived_time_end = "";
				}
				if (this.invoice_time) {
					this.form.invoiced_time_start = parseInt(this.invoice_time[0] / 1000);
					this.form.invoiced_time_end= parseInt(this.invoice_time[1] / 1000);
				} else {
					this.form.invoiced_time_start = "";
					this.form.invoiced_time_end = "";
				}
				if(this.creat_time_arr1){
					this.form.creat_time_arr[0] = parseInt(this.creat_time_arr1[0] / 1000);
				    this.form.creat_time_arr[1] = parseInt(this.creat_time_arr1[1] / 1000);
				}else{
					this.form.creat_time_arr = []
				}
				if(this.insure_time_arr){
					this.form.insure_time_arr[0] = parseInt(this.insure_time_arr[0] / 1000);
				    this.form.insure_time_arr[1] = parseInt(this.insure_time_arr[1] / 1000);
				}else{
					this.form.insure_time_arr = []
				}
				if(this.logink_up_time_arr){
					this.form.logink_up_time_arr[0] = parseInt(this.logink_up_time_arr[0] / 1000);
				    this.form.logink_up_time_arr[1] = parseInt(this.logink_up_time_arr[1] / 1000);
				}else{
					this.form.logink_up_time_arr = []
				}
				//读取
				this.get_page_data()
			},
			
			//清空查询条件
			ser_para_clear(e){
				this.form={
					
					//运单编号相关
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游运单编号
					bl_num:'',//线路编号
					group1_num:'',//组别编号
					is_set_freight:'',
					//货主相关
					shipper_tel:'',//货主手机号
					shipper_tel_not:'',//非货主手机号
					shipper_user_name:'',//货主姓名
					shipper_company_name:'',//货主公司名称
					area_id:'',//大区搜索
					saler_tel:'',//销售电话搜索
					//收款人/司机/车辆
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					bl_name:'', //线路名称
					check_act_status:'',//轨迹验证状态搜索
					//状态相关
					status:'',//状态(1:待发车,2:运输中,3:已到货)
					lock_status:'',//锁定状态(1:已锁定,2:未锁定/正常)
					payed_status:'',//支付状态(1:未支付,2:部分支付,3:支付完毕)
					invoiced_status:'',//开票状态(1:未开票,2:部分开票,3:开票完毕)
					settled_status:'',//结算状态(1:未结算,2:部分结算,3:结算完毕)
					upl_status:'',//上报状态(1:未上报,2:运单上报成功,3:运单上报失败,4:出金流水上报成功,5:出金上报失败)
					upl_aim:'',//运单上报目标,上报后有值(类似:zhejiang;anhui....)
					voucher_status:'',//回单是否上传
					id:'',
					etc_status:'',

					item_name:'',//项目名称
					logink_up_status:'',
					is_set_insure:'',//保障服务费

					is_yufu:'',

					is_admin_pay_return:'',
					//货源数据相关
					cargo_name:'',//货物名称
					cargo_type:'',//货物类型
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					match_way:'',//匹配方式(1:收款人抢单,2:货主指定后收款人接单,3:收款人竞价,4:收款人扫码接单,5:网点线下匹配后报单,6:合作方协助匹配后报单,7:后台匹配,8:后台添加)
					outlets_num:'',//网点编号

					//货车类型相关
					need_truck_type:'',//租用货车类型
					need_truck_type2:'',//租用货车细分类型
					need_truck_types:null,//货车类型

					//发到货地相关
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县

					start_time_start:'',
					arrived_time_end:'',

					invoiced_time_start:'', //开票开始时间
                    invoiced_time_end:'',//开票结束时间

					//时效相关
					cargo_creat_time:null,//货源创建时间
					creat_time:null,//成交时间(运单创建时间)
					start_time:null,//发车时间
					arrived_time:null,//到货时间
					payed_time:null,//支付完成时间
					invoiced_time:null,//开票完成时间
					settled_time:null,//结算完成时间
					tord_upld_time:null,//运单上报时间
					outcash_upld_time:null,//出金流水上报时间

					is_show:'',

					creat_time_arr:[]
				}
				this.date_value=''
				this.invoice_time = ''
				if(e !=1){
					//读取
					this.get_page_data()
				}
			
				
			},

			//导出搜索结果
			tord_export_open(){//打开界面

				//置入条件
				this.tord_export.rull=this.form

				//打开界面
				this.tord_export.is_show++
			},
			
			tord_export_close(){//关闭界面

				//关闭界面
				this.tord_export.is_show=0
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					// return;
				}

				//置为加载中
				this.list.loading=true;
				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_list_admin',
						is_get_shipper_info:1,
						is_get_shipper_company_info:1,
						is_get_truck_owner_info:1,
						is_get_driver_info:1,
						is_get_truck_info:1,
						is_get_tord_outlets:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{
						if(data.code ==0){
							//加载完毕
							this.list.loading=false

							//总数
							this.list.total=data.msg.max

							//总金额
							this.list.total_freight = data.msg.total.total_freight

							this.list.emailed_bili = data.msg.total.emailed_bili
							//总服务费
							this.list.total_service = data.msg.total.total_service

							//总计费用
							this.total = (Number(data.msg.total.total_freight) + Number(data.msg.total.total_service)).toFixed(2)
							//预处理
							for(let item of data.msg.list){
								item.cargo_weight = Number(item.cargo_weight).toFixed(2)

								item.yufu_apply_time_text = this.$my.other.totime(item.yufu_apply_time);
								//成交时间
								item.creat_time_text=this.$my.other.totime(item.creat_time);
								
								item.deal_time_text=this.$my.other.totime(item.deal_time);
								//发车时间
								if(item.start_time>0){//已发车
									item.start_time_text=this.$my.other.totime(item.start_time);
								}else item.start_time_text="未发车";
								
								//到货时间
								if(item.arrived_time>0){//已到货
									item.arrived_time_text=this.$my.other.totime(item.arrived_time);
								}else item.arrived_time_text="未到货";

								//装货联系人
								if(item.case_link_man){
									item.case_link_man_text=item.case_link_man;
								}else item.case_link_man_text="无";

								//装货联系电话
								if(item.case_link_tel){
									item.case_link_tel_text=item.case_link_tel;
								}else item.case_link_tel_text="无";

								//卸货联系人
								if(item.aim_link_man){
									item.aim_link_man_text=item.aim_link_man;
								}else item.aim_link_man_text="无";

								//卸货联系电话
								if(item.aim_link_tel){
									item.aim_link_tel_text=item.aim_link_tel;
								}else item.aim_link_tel_text="无";

								/* 装货地其他数据(json) */
								item.case_other_obj=JSON.parse(item.case_other);

								//装货时间
								if(!item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//只有止点

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_end)+"以前";

								}else if(item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//只有起点

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+"以后";

								}else if(item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//都有

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(item.case_other_obj.case_time_end);

								}else if(!item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//都没有

									item.case_other_obj.case_date="未设置";
								}

								//发车时间
								if(!item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//只有止点

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_2)+"以前";

								}else if(item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//只有起点

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+"以后";

								}else if(item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//都有

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+" ~ "+this.$my.other.todate(item.case_other_obj.start_time_2);

								}else if(!item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//都没有
									
									item.case_other_obj.start_time="未设置";
								}

								//发车时间是否强制校验
								switch(item.case_other_obj.start_time_is_check){
									case '1':item.case_other_obj.start_time_is_check_text="(强制)";break;
									case '2':item.case_other_obj.start_time_is_check_text="(不强制)";break;
								}

								//异常运单是否验证
								switch(item.check_act_status){
									case '1':item.check_act_status_text="已验证";break;
									case '2':item.check_act_status_text="未验证";break;
									case '2':item.check_act_status_text="验证失败";break;
								}
								if(item.case_other_obj.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
									item.case_other_obj.start_time_is_check_text=""
								}
								/* 卸货地其他数据(json) */
								item.aim_other_obj=JSON.parse(item.aim_other);
								// item.aim_other_obj = item.aim_other_obj.replace(/\\/g, '');
								//卸货时间
								if(!item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//只有止点

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_end)+"以前";

								}else if(item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//只有起点

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+"以后";

								}else if(item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//都有

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(item.aim_other_obj.aim_time_end);

								}else if(!item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//都没有
									
									item.aim_other_obj.aim_date="未设置";
								}

								//运单状态
								switch(item.status){
									case '1':item.status_text="待发车";break;
									case '2':item.status_text="运输中";break;
									case '3':item.status_text="已到货";break;
								}

								//锁定状态
								switch(item.lock_status){
									case '1':item.lock_status_text="已锁定";break;
									case '2':item.lock_status_text="未锁定";break;
								}
								
								//上报状态
								switch(item.tax_upl_status){
									case '1':item.tax_upl_status_text="未同步";break;
									case '2':item.tax_upl_status_text="已同步尚未第一次上传";break;
									case '3':item.tax_upl_status_text="第一次已上传";break;
									case '4':item.tax_upl_status_text="一次失败";break;
									case '5':item.tax_upl_status_text="二次已上传";break;
									case '6':item.tax_upl_status_text="二次失败";break;
									case '7':item.tax_upl_status_text="三次已上传";break;
									case '8':item.tax_upl_status_text="三次失败";break;
									case '9':item.tax_upl_status_text="其他";break;
								}
								//省监管上报状态
								switch(item.logink_up_status){
									case '1':item.logink_up_status_text="未上传";break;
									case '0':item.logink_up_status_text="一次上传失败";break;
									case '2':item.logink_up_status_text="一次已传";break;
									case '3':item.logink_up_status_text="二次已传";break;
									case '4':item.logink_up_status_text="车辆已传";break;
									case '5':item.logink_up_status_text="司机已传";break;
									case '6':item.logink_up_status_text="承运人已传";break;
									case '7':item.logink_up_status_text="三次已传";break;
									case '8':item.logink_up_status_text="流水已传";break;
								}

								//邮件发送状态
								switch(item.insure_status){
									case '1':item.insure_status_text="邮件已发送";break;
									case '2':item.insure_status_text="邮件未发送";break;
								}
								//保障服务费
								switch(item.is_set_insure){
									case '2':item.is_set_insure_text="已设置";break;
									case '3':item.is_set_insure_text="未设置";break;
								}
								//结算方式
								switch(item.settlement_type){
									case '1':item.settlement_type_text="按吨数";break;
									case '2':item.settlement_type_text="按方数";break;
									case '3':item.settlement_type_text="按趟";break;
								}
								
								//是否回程
								switch(item.is_need_return){
									case '1':item.is_need_return_text="需要回程";break;
									case '2':item.is_need_return_text="不需要回程";break;
								}

								//匹配方式
								switch(item.match_way){
									case '1':item.match_way_text="收款人抢单";break;
									case '2':item.match_way_text="指定后收款人接单";break;
									case '3':item.match_way_text="收款人竞价中标";break;
									case '4':item.match_way_text="收款人扫码接单";break;
									case '5':item.match_way_text="网点线下匹配";break;
									case '6':item.match_way_text="合作方匹配";break;
									case '7':item.match_way_text="后台匹配";break;
									case '8':item.match_way_text="后台添加";break;
								}

								//应收总额
								if(item.cost_info.freight_total){
									item.cost_info.freight_total_text=item.cost_info.freight_total+" 元";
								}else item.cost_info.freight_total_text="未设置";

								//应付总额
								if(item.cost_info.give_carrier_total){
									item.cost_info.give_carrier_total_text=item.cost_info.give_carrier_total+" 元";
								}else item.cost_info.give_carrier_total_text="未设置";

								//已付总额
								item.cost_info.has_payed_total_text=item.cost_info.has_payed_total+" 元"

								//运费支付状态
								switch(item.payed_status){
									case '1':item.payed_status_text="未支付";break;
									case '2':item.payed_status_text="部分支付";break;
									case '3':item.payed_status_text="支付完毕";break;
								}

								//运费结算状态
								switch(item.settled_status){
									case '1':item.settled_status_text="未结算";break;
									case '2':item.settled_status_text="部分结算";break;
									case '3':item.settled_status_text="结算完毕";break;
								}

								//货车类型
								item.truck_info.truck_type_name_text=`
									${item.truck_info.truck_type_name?'/'+item.truck_info.truck_type_name+'/':''}
									${item.truck_info.truck_type_name2?item.truck_info.truck_type_name2:''}
								`;

								item.text = ''
								//上报
								switch(item.upl_status){
									case '1':item.upl_text="未上报";break;
									case '2':item.upl_text="运单上报成功";break;
									case '3':item.upl_text="运单上报失败";break;
									case '4':item.upl_text="流水上报成功";break;
									case '5':item.upl_text="流水上报失败";break;
								}
								if(item.upl_aim)item.upl_text+=`(${this.logink_aim_list[item.upl_aim]})`

								//运单轨迹状态
								switch(item.trajectory_status){
									case '1':item.trajectory_status_text="未校验";break;
									case '2':item.trajectory_status_text="轨迹合规";break;
									case '3':item.trajectory_status_text="轨迹不合规";break;
									case '4':item.trajectory_status_text="无轨迹";break;
								}
							}

							//渲染
							this.list.data=data.msg.list

						}else{
							this.$my.other.msg({
								str:data.error_info,
								type:'info'
							});
							this.list.loading=false
							this.list.data=[]
						}

					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		// height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:1000px;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
		.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		
		span {
			margin-left: 20px;
		}
	}
	.elfrom_item{
		width:auto;
		margin-right:10px;
	}
	/deep/.el-button--text{
		color: #409eff !important;
	}
	.btn_delete{
		color:red !important
	}
	.iframe-container {
		position: relative;
		width: 100%;
		padding-bottom: 56.25%; /* 16:9 aspect ratio */
		height: 0;
	}
	.iframe-container iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
</style>